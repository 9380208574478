// jekyll needs the dashes to process the sass file
@import "./bootstrap-custom.scss";
a {
  color: $primary;

  &:hover {
    color: #02772f;
    text-decoration: none;
    transition: all 0.2s;
  }
}

.btn-link {
  color: $primary;

  &:hover {
    color: #02772f;
    text-decoration: none;
    transition: all 0.2s;
  }
}

// Sticky footer, see: https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/
html, body {
  height: 100vh;
}

main {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
  font-size: 14px;
}

.nav-padding-xlwings {
  // account for fixed navbar
  flex-shrink: 0;
  height: 78px;
}

.navbar-toggler {
  border: 0;
}

.navbar .nav-link .badge {
  vertical-align: middle;
}

.border-2 {
  border-width: 2px !important;
}

.roboto-bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.display-4 {
  //font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 3.3rem;
}

.lead {
  font-size: 1.2rem;
}

#hero-section, #collection-hero {
  padding: 0.1px;
  position: relative;
}

.hero-diagonal {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 85%, 100% 0);
    clip-path: polygon(0 0, 0 100%, 100% 85%, 100% 0);
    background-color: $primary;
    z-index: -1;
  }
}

.img-company-logo {
  max-height: 60px;
}

#sponsor-list {
  img {
    padding-right: 90px;
  }

  img:last-child {
    padding-right: 0;
  }
}

.current-link {
  color: $primary !important;

  &:hover {
    color: $dark !important;
  }
}

.link-light {
  color: $light !important;

  &:hover {
    color: $primary !important;
  }
}

.link-dark {
  color: $dark !important;

  &:hover {
    color: $light !important;
  }
}

.link-dark-green {
  color: $dark !important;

  &:hover {
    color: $primary !important;
  }
}


.video-padding {
  padding: 15% !important;
}

.collection-link {
  min-width: 12rem;
}

.collection-video {
  max-width: 700px;
}

.video-title {
  min-height: 4rem;
}

// .video-item{
//   border: 0;
//   border-bottom: 2px solid $primary;
// }
.quote-icon {
  position: absolute;
  left: 0.5rem;
  top: 0;
  opacity: 0.2;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: $primary;
  border-color: $primary;
}

.page-link:hover {
  z-index: 2;
  color: $primary;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: $primary;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.btn-xlwings {
  font-size: 0.9rem;
  //font-family: 'Roboto', sans-serif;
  padding: 0.325rem 1rem;
}

.markdown-content {
  h1, h2, h3, h4, h5, h6 {
    color: $primary;
  }

  h1 {
    margin-bottom: 2.25rem;
  }

  h2 {
    margin-bottom: 2rem;
  }

  h3 {
    margin-bottom: 1.75rem;
  }

  h4 {
    margin-bottom: 1.5rem;
  }

  .img-fluid {
    border: 1px solid #969494;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.blog-content {
  h1 {
   color: $primary
  }

  h2 {
    /* This makes sure that the titles don't disappear underneath the fixed
    header when jumped to from the overview */
    padding-top: 100px;
    margin-top: -80px;
  }
  
  h3 {
    color: #000000;
  }
}

/*
SPONSOR LOGO ADJUSTMENTS
TO KEEP THEM ALIGNED
*/

// Mobile first
#accenture-logo {
  height: 30px;
  padding-bottom: 8px;
}

#nokia-logo {
  height: 20px;
}

#aqr-logo {
  height: 55px;
}

#bnp-logo {
  height: 35px;
}

#columbia-logo {
  height: 20px;
}

#shell-logo {
  height: 45px;
}

#eucom-logo {
  height: 65px;
}

pre {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 9.5px;
}

pre code {
  font-size: 90%;
}

// Table formatting

table {
  @extend .table;
  @extend .table-sm;
  @extend .table-striped;
  @extend .table-responsive-md;
}

thead {
  @extend .thead-dark;
}

table.fixed-table {
  width: 100%;
  table-layout: fixed;
}

// Font awesome

.fa-check {
  color: #1dbd21;
}

.fa-times {
  color: red;
}

.fa-info-circle{
  color: #00A83F;
}

// Responsive images on blog

.markdown-content {
  img {
    @extend .img-fluid;
  }
}

// Optional
.img-max {
  max-width: 150px;
  width:100%;
}

blockquote {
  p {
    background-color: #e5e5e5;
    color: #333 !important;
    border-left: 4px solid #02772f !important;
    padding: 10px;
  }
}

.fa-ul {
  margin-left: 1.5em !important;
}

// https://google-webfonts-helper.herokuapp.com/

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
