@import "../../node_modules/bootstrap/scss/functions.scss";
@import "../../node_modules/bootstrap/scss/variables.scss";

$primary: #00A83F;
$light-grey: #cecece;
$theme-colors: (
  "primary": $primary,
  "light-grey": $light-grey
);

$screen-ss: 260px;
$screen-sm: 480px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0px,
  ss: $screen-ss,
  // Small screen / phone
  sm: $screen-sm,
  // Medium screen / tablet
  md: $screen-md,
  // Large screen / desktop
  lg: $screen-lg,
  // Extra large screen / wide desktop
  xl: $screen-xl
);

@import "../../node_modules/bootstrap/scss/bootstrap.scss";